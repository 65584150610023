<template>
  <AuctionManagement api-url="auctions-not-authorized" />
</template>
<script>
import AuctionManagement from "@/components/Auctions/AuctionManagement";

export default {
  components: { AuctionManagement },
};
</script>
